/* global require, Worker, URL, Blob, process */

import duckdb from "./duckdb";
duckdb.getDB();

const uniq = (l) =>
      [... new Set(l)];


// umm, yeah, this is just going to download the lot of it and stuff it into memory.

export class DataLayer {
  constructor() {
    this._db_init = false;
    this._setDbInit = false;
    this._ts = new Date().valueOf();
    this._cache_bust = false;

    this._columns = [
      { key: 'region', name: 'Region'},
      { key: 'country', name: 'Country'},
      { key: 'utility_short_display', name: 'Utility'},
      { key: 'indicator', name: 'Indicator'},
      { key: 'ownership', name: 'Ownership'},
      { key: 'utility_type', name: 'Utility Type'},
      { key: 'display_unit', name: 'Units'},
    ];
  }

  async init_db (setDbInit, setColumns) {
    if (this._db_init) {
      console.log('already created table');
      return this._db_init;
    }
    if (setDbInit) {
      this._setDbInit = setDbInit;
    }
    let db=await duckdb.getDB();
    if (db !== undefined) {
      const con = await db.connect();
      if (this._db_init) {
        console.log('already created table');
        return this._db_init;
      }
      try {
        const res = await con.query("select count(*)::int as ct from information_schema.tables where table_name='data'");
        if (res.toArray()[0].toJSON().ct >0) {
          this._db_init = true;
          return this._db_init;
        }
      } catch (e) {
        console.error("Error checking for the data table");
        console.log(e);
        // falls apart?
      }
      try {
        console.log('loading data');
        let url;
        if (process.env.NODE_ENV === 'development') {
          url = "http://localhost:8000/data/raw.parquet";
        } else {
          url = document.location.origin + "/static/data/raw.parquet";
        }
        url = url + (this._cache_bust ? `r=${this._ts}` : '');
        const downloaded_parquet = await fetch(url);
        await db.registerFileBuffer('buffer.parquet', new Uint8Array(await downloaded_parquet.arrayBuffer()));
        await con.query(`create table data as (select * from "buffer.parquet")`);
        console.log('getting years');
        const years = await this.query('select distinct year(year)::int as year from data order by year', []);
        this.years = years;
        console.log('creating view');
        setColumns && this.getColumns(setColumns);
        await con.query(`
           create view data_wide as (
                 pivot data on year(year)::int in (${years.map(e=>e.year).join(',')}) using first(value))
             `);
        console.log('done with tables');
      }
      catch(e) {
        // race condition, will probably be ok next time around
        console.log("Race condition creating data");
        console.debug(e);
        // sometimes we get an invalid parquet file because it's served from cache in the browser.
        // this will force it to be updated
        this._cache_bust = true;
        return this._db_init;
      }
      this._db_init = true;
      console.log('created table');
    } else {
      console.log('db undefined, setting timeout');
      // sometimes need to call this twice
      window.setTimeout(() => this.init_db(setDbInit, setColumns), 500);
    }
    if (setDbInit) {
      console.log(`setting dbinit: ${this._db_init}`);
      setDbInit(this._db_init);
    } else if (this._setDbInit) {
      console.log("calling cached setDbInit");
      this._setDbInit(this._db_init);
    } else {
      console.log("all setDbInit undefined");
    }
    return this._db_init;
  };

  async getColumns (setColumns) {
    setColumns([...this._columns, ...this.years.map(e=>({key: e.year, name: e.year}))]);
  };

  isDbInit() {
    return this._db_init;
  }

  async query (statement, params) {
    console.log('query');
    let db = await duckdb.getDB();
    const con = await db.connect();
    const stmt = await con.prepare(statement);
    const res = await stmt.query(...params);
    const result = res.toArray().map((row) => row.toJSON());
    await con.close();
    console.log('query.done');
    return result;
  };

  async queryCommand(statement) {
    console.log('queryCommand');
    let db = await duckdb.getDB();
    const con = await db.connect();
    const stmt = await con.query(statement);
    await con.close();
    console.log('queryCommand.done');
  }

  async gen_dl(statement, params) {
    let db = await duckdb.getDB();
    const con = await db.connect();
    const stmt = await con.prepare(`copy (${statement}) to 'tmp.csv' (FORMAT 'csv', header true);`);
    const res = await stmt.query(...params);
    const buffer = await db.copyFileToBuffer('tmp.csv');
    await db.dropFile('tmp.csv');
    const link = URL.createObjectURL(new Blob([buffer]));
    await con.close();
    return link;
  }

  async getData(indicator, context, utility) {
    console.log('getting data');
    let query = `select utility, utility_short_display, country, utility_type, year(year)::int as year, value from data where indicator=?`;
    let params = [indicator];
    if (utility && context != 'all') {
      query += ` and ${context} = (select ${context} from data where utility=? limit 1)`;
      params = [indicator, utility];
    }

    return await this.query (query, params);

  };

  async getGridData(indicator, context, utility) {
    console.log('getting grid data');
    let query = `select * from data_wide where indicator=? order by country, utility`;
    let params = [indicator];

    return await this.query (query, params);

  };

  async getUtilityDashboardData(utility) {
    console.log("getting dashboard utility data");
    const query = `select
                     indicator,
                     year(max(year))::int as year,
                     first(value order by year desc) as value,
                     first(display_unit) as display_unit
                   from data
                   where utility=?
                   group by indicator`;
    const params=[utility];
    return await this.query(query, params);
  }

  async getDashboardIndicatorMedians() {
    console.log("getting indicator medians");
    const query = `select
                     indicator,
                     quantile_disc(value, [0.25, 0.75])::double[] as medians
                   from (select
                       indicator,
                       year(max(year))::int as year,
                       first(value order by year desc) as value,
                     from data
                     group by indicator, utility)
                   group by indicator`;
    return await this.query(query,[]);
  }

  async getUtilityGridData(utility) {
    console.log("Getting utility grid data for " + utility);
    let query = `select * from data_wide where utility=? order by indicator`;
    let params = [utility];

    return await this.query (query, params);
  }

  async getUtilityIndicatorData(utility, indicators) {
    const created = await this.query("select count(*)::int as ct from information_schema.tables where table_name=?", ['dashboard_indicators']);
    if (created[0].ct == 0) {
      const indicatorValues = indicators.map((e)=>`'${e.replaceAll("'", "\'")}'`).join(',');
      const indicatorCols = indicators.map(e=>`"${e}"`).join(',');
      const query = `create view dashboard_indicators as pivot (
                       select
                       utility,
                       indicator,
                       year(year)::int as year,
                       value
                     from data
                     where indicator in (${indicatorValues})) on indicator in (${indicatorCols}) using first(value)`;
      await this.queryCommand (query);
    };
    return await this.query("select * from dashboard_indicators where utility=?", [utility]);
  }

  async csvDownload(indicator, context, utility) {
    console.log('csv download');
    let query = `select * from data_wide where indicator=? order by country, utility`;
    let params = [indicator];

    const link = await this.gen_dl (query, params);
    console.log(link);
    const a = document.createElement('a');
    a.href=link;
    a.download=`${indicator}.csv`;
    a.filename=`${indicator}.csv`;
    a.target='_blank';
    a.type='text/csv';
    console.log(a);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  async csvDownloadForUtility(utility) {
    console.log('csv download');
    let query = `select * from data_wide where utility=? order by country, utility`;
    let params = [utility];

    const link = await this.gen_dl (query, params);
    console.log(link);
    const a = document.createElement('a');
    a.href=link;
    a.download=`${utility}.csv`;
    a.filename=`${utility}.csv`;
    a.target='_blank';
    a.type='text/csv';
    console.log(a);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  async getMedians(indicator, context, utility) {
    console.log('getting medians');
    if (!utility || context =='all') {
      return await this.query (`select
                          year(year)::int as year,
                          max(value) as max,
                          min(value) as min,
                          quantile_disc(value, [0.1, 0.25, 0.5, 0.75, 0.9])::double[] as medians
                        from data
                        where indicator=?
                        group by year
                        having count(*) > 5`,
                               [indicator]);
    } else {
      return await this.query (`select
                          year(year)::int as year,
                          max(value) as max,
                          min(value) as min,
                          quantile_disc(value, [0.1, 0.25, 0.5, 0.75, 0.9])::double[] as medians
                        from data
                        where indicator=?
                          and ${context} = (select ${context} from data where utility=? limit 1)
                        group by year
                        having count(*) > 5`,
                               [indicator, utility]);
    }
  };

};

// const DataLayer = {
//   init_db:init_db,
//   getColumns:getColumns,
//   isDbInit:isDbInit,
//   query:query,
//   csvDownload:csvDownload,
//   getData:getData,
//   getGridData:getGridData,
//   getMedians:getMedians,
// };
